<template>
	<div>
		<div style="background: #2f54eb;padding:16px 20px;border-top-left-radius: 10px;border-bottom-left-radius: 10px;cursor: pointer;" v-if="visible==false" @click="open">
			<a-icon type="setting" style="color:#fff;" />
		</div>
		<a-drawer
		      title="Basic Drawer"
			  :closable="true"
		      :placement="'right'"
			  :mask="false"
		      :visible="visible"
			  @close="onClose"
		    >
		      <p>Some contents...</p>
		      <p>Some contents...</p>
		      <p>Some contents...</p>
		</a-drawer>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				visible:false,
			}
		},
		
		created() {
			
		},
		
		methods: {
			//----------------关闭--------------------
			onClose:function(){
				this.visible=false
			},
			
			//--------------打开------------------
			open:function(){
				this.visible=true
			}
		}
	}
</script>

<style>
</style>