<template>
	<fragment>
		<template v-for="(item, index) in subMenuList" v-if="item.isvisible==true">
			<el-submenu v-if="item.subList && item.subList.length > 0" :key="index" :index="item.menupath">
				<template slot="title">
					<!-- <i :class="item.menuico"></i> -->
					<a-icon :component="allIcon[item.menuico + 'Icon']" v-if="allIcon[item.menuico + 'Icon']" style="padding-right:10px;" class="selectIcon" />
					<a-icon :type="item.menuico" v-if="!allIcon[item.menuico + 'Icon']" style="padding-right:10px;" />
					<span>{{ item.menuname }}</span>
				</template>
				<el-menu-item-group>
					<!-- <span slot="title">{{ item.title }}</span> -->
					<MenuTree :subMenuList="item.subList" />
				</el-menu-item-group>
			</el-submenu>
			
			<app-link v-else :to="resolvePath(item.menupath,item.query)">
				<el-menu-item :key="index" :index="item.menupath">
					<div style="display: flex;align-items: center;height: 100%;">
						<a-icon :component="allIcon[item.menuico + 'Icon']" v-if="allIcon[item.menuico + 'Icon']" style="padding-right:10px;" class="selectIcon" />
						<a-icon :type="item.menuico" v-if="!allIcon[item.menuico + 'Icon']" style="padding-right:10px;" />
						<item v-if="!isCollapse" :title="item.menuname"></item>
					</div>
				</el-menu-item>
			</app-link>
		</template>
	</fragment>
</template>

<script>
	import path from 'path'
	import MenuTree from './menu'
	import {
		isExternal
	} from '@/utils/validate'
	import AppLink from './Link'
	import Item from './Item'
	import Cookies from "js-cookie";
	import allIcon from '@/core/icons'
	export default {
		name: "MenuTree", //必须要 递归组件的关键，并且要与引入组件的变量名称一致
		components: {
			MenuTree,
			AppLink,
			Item
		},
		props: {
			subMenuList: {
				//接收到的菜单信息
				type: Array,
				default: [],
			},
			authority: {
				//填报权限 0 1不展示
				type: Number,
				default: 0,
			},
			mode: {
				//菜单 水平  垂直
				type: String,
				default: "horizontal",
			},
		},
		data() {
			return {
				role:null,//权限
				allIcon,
				isCollapse:false,
			};
		},
		//生命周期 - 创建完成
		created() {
			this.isCollapse=this.$store.state.Layout.isActive;
			// console.log("subMenuList", this.subMenuList)
			this.role = Cookies.get("role")
			// console.log("subMenuList", this.role)
		},
		watch:{
			watch: {
				isActive1(val) {
					this.isCollapse = val;
				}
			},
		},
		computed: {
			isActive1() {
				return this.$store.state.Layout.isActive;
			}
		},
		//DOM挂载完毕
		mounted() {},
		methods: {
			resolvePath(routePath, routeQuery) {
				if (isExternal(routePath)) {
					return routePath
				}
				if (routeQuery) {
					let query = JSON.parse(routeQuery);
					return {
						path: path.resolve(routePath),
						query: query
					}
				}
				return path.resolve(routePath)
			}
		},
	};
</script>
<style lang='less' scoped>
	.fx_col {
		display: flex;
		flex-direction: column;
	}
	
	.fx {
		display: flex;
	}
	
	.el-menu-item{
	    flex-direction: column;
	    justify-content: center;
	}
	.el-menu-item .i-icon{
	  margin-bottom:2px;
	}
	.el-menu-item span{
	    line-height:16px;
	}
	.el-menu-item{
	    height:35px;
	}
	.el-menu-item:hover{
	    background-color: rgba(0, 0, 0, 0.1) !important;
	}
	.el-menu-item .i-icon{
	    display:  none;
	}
	.el-menu-item {
	     height: 30px;
	 }
	@media(min-height:300px) {
	 .el-menu-item {
	      height: 35px;
	 	  font-size:12px;
	  }
	 }
	@media(min-height:500px) {
	 .el-menu-item .i-icon{
	     display:inherit;
	 }
	 .el-menu-item {
	      height: 35px;
		  font-size:14px;
	  }
	 }
	 @media(min-height:700px) {
	 .el-menu-item .i-icon{
	     display:inherit;
	 }
	 .el-menu-item {
	      height: 35px;
		  font-size:14px;
	  }
	 }
	 @media(min-height:800px) {
	 .el-menu-item .i-icon{
	     display:inherit;
	 }
	 .el-menu-item {
	      height: 35px;
		  font-size:14px;
	  }
	 }
	@media(min-height:900px) {
	.el-menu-item .i-icon{
		display:inherit;
	}
	
	 .el-menu-item {
	      height:35px;
		  font-size:14px;
	  }
	 }
	 .el-menu-vertical{
	      padding-top:16px;
	 }
	
	.el-menu-item.is-active{
	     background-color: var(--el-border-color-light);
	}
	

</style>
