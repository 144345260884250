<template>
	<div class="tab-head" style="width:600px;">
		<el-tabs v-model="editableTabsValue" type="card" tab-position="bottom" @tab-click="pushShow"
			@tab-remove="removeTab">
			<el-tab-pane v-for="item in editableTabs" :key="item.name" :label="item.title" :name="item.name"
				:closable="item.closable">
				<template #label v-if="item.name!=='/erp/home'">
					<a-dropdown :trigger="['contextmenu']">
						<span :style="item.name == editableTabsValue ? 'color:#fff' : ''">{{item.tilte}}</span>
						<template v-slot:overlay>
							<a-menu style="user-select: none">
								<a-menu-item @click="removeTab(item.name)" key="1">
									<a-icon type="close" />
									关闭
								</a-menu-item>
								<a-menu-divider />
								<a-menu-item @click="closeLeft()" key="2">
									<a-icon type="vertical-right" />
									关闭左侧
								</a-menu-item>
								<a-menu-item @click="closeRight()" key="3">
									<a-icon type="vertical-left" />
									关闭右侧
								</a-menu-item>
								<a-menu-divider />
								<a-menu-item @click="closeOther()" key="6">
									<a-icon type="minus" />
									关闭所有
								</a-menu-item>
							</a-menu>
						</template>
					</a-dropdown>
				</template>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	import router from '../../../../router'
	import {
		changeKeywords,
		changeDescription,
		changeTitle
	} from '@/utils/brower.js'
	export default {
		data() {
			return {
				editableTabsValue: "/erp/home",
				editableTabs: [{
					path: 'home',
					title: '首页',
					name: '/erp/home',
					closable: false,
					meta: {
						keepAlive: true
					},
				}]
			}
		},

		created() {
			let editableTabs = JSON.parse(sessionStorage.getItem("setTabs2"));
			// console.log('路由跳转触发',editableTabs)
			// if (editableTabs) {
			// 	this.editableTabs = editableTabs;
			// }
			changeTitle("格物思——" + this.$route.meta.name)
			this.editableTabsValue = this.$route.name;
			this.routeChange();
		},

		mounted() {
			
		},
		
		activated(){
			let editableTabs = JSON.parse(sessionStorage.getItem("setTabs2"));
			if (editableTabs) {
				this.editableTabs = editableTabs;
			}
			// console.log('路由跳转触发',this.editableTabs)
			// console.log(this.$route)
			this.editableTabsValue = this.$route.name;
			// console.log("111",this.editableTabsValue)
		},
		watch: {
			// $route(to, from) {
			// 	let editableTabs = JSON.parse(sessionStorage.getItem("setTabs2"));
			// 	if (editableTabs) {
			// 		this.editableTabs = editableTabs;
			// 	}
			// 	this.routeChange();
			// }
			$route: {
				handler(val, oldval) {
					changeTitle("格物思——" + this.$route.meta.name)
					// console.log("新路由信息",val); //新路由信息
					// console.log("老路由信息",oldval); //老路由信息
					// console.log("老路由信息",JSON.parse(sessionStorage.getItem("newProducts"))); //老路由信息
					if (JSON.parse(sessionStorage.getItem("newProducts"))) {
						this.removeTab(JSON.parse(sessionStorage.getItem("newProducts")))
					} else {
						this.routeChange();
					}
				},
				// 深度观察监听
				deep: true
			}


		},

		computed: {

		},

		methods: {
			routeChange: function() {
				router.afterEach((to, from) => {
					// console.log("this.$route",this.$route)
					// console.log("12321",JSON.parse(sessionStorage.getItem("setTabs2")))
					if (to.path !== '/' && to.path !== '/valuation' && to.path !==
						'/boardMaking/pcbBoardMaking' && to.path !== '/manufacture/pcbaMade' && to.path !==
						'/bomSupply' && to.path !== '/solution/schemeDesign' && to.path !==
						'/factoryDisplay/displayFactory' && to.path !== '/aboutUs/aboutUs' && to.path !==
						'/boardMaking/pcbBatch' && to.path !== '/boardMaking/fpcBatch' && to.path !==
						'/boardMaking/aluminumSubstrate' && to.path !== '/manufacture/steelMesh' && to.path !==
						'/bomSupply' && to.path !== '/solution/layoutDesign' && to.path !==
						'/factoryDisplay/iaminatedStructure' && to.path !== '/factoryDisplay/processShow' && to
						.path !== '/factoryDisplay/productList' && to.path !== '/factoryDisplay/activity' && to
						.path !== '/factoryDisplay/sunbathing' && to.path !== '/aboutUs/contactUs' && to
						.path !== '/aboutUs/news' && to.path !== '/login' && to.path !== '/register' && to.path !== '/userLogin'
						&& to.path !== '/order/home' && to.path !== '/order/orderManagement' && to.path !== '/order/orderManagement/pcbOrder'
						 && to.path !== '/order/orderManagement/smtOrder' && to.path !== '/order/orderManagement/seelOrder' && to.path !== '/order/orderManagement/bomOrder'
						 && to.path !== '/order/userInfo' && to.path !== '/order/userInfo/basicInformation' && to.path !== '/order/userInfo/address'
						 && to.path !== '/order/userInfo/changePassword' && to.path !== '/order/userInfo/consumptionRecords' && to.path !== '/order/userInfo/obtainingRecords'
						 && to.path !== '/order/userInfo/changePassword' && to.path !== '/erp/home/' && to.path !== '/erp/sellManage/Invoicing?orderstatus=已完成') {
						// 如果to.path该页签存在于当前数组中---就执行激活当前页签---否则就新增加页签
						let newarr = []
						let oldname = this.editableTabsValue;
						this.editableTabs.forEach((item) => {
							newarr.push(item.name)
						})
						// console.log("路由化222",this.editableTabs)
						if (newarr.indexOf(to.name) > -1) {
							this.editableTabsValue = to.name;
							let tab = {};
							tab.closable = this.$route.path == '/erp/home' ? false : true;
							tab.path = this.$route.path;
							tab.name = this.$route.name;
							tab.meta = this.$route.meta;
							var tabquery = this.editableTabs[newarr.indexOf(to.name)];
							this.editableTabs[newarr.indexOf(to.name)] = Object.assign(tabquery, tab);
							// console.log("222", this.editableTabs)
							// this.$nextTick()
							// console.log(this.editableTabs)
							sessionStorage.setItem("setTabs2", JSON.stringify(this.editableTabs));
						} else {
							this.addTab();
						}
					}
				})
			},

			pushShow: function(target) {
				// console.log("target",target)
				this.showTab(target._props.name)
			},

			showTab: function(activeName) {
				let tabs = this.editableTabs;
				let query = null;
				if (tabs) {
					query = tabs[0];
				}
				tabs.forEach((tab, index) => {
					if (tab.name === activeName) {
						query = tab;
					}
				});
				// console.log(tabs)
				let meta = query.meta;
				query.meta = "";
				router.push({
					//删除自己时路由切换到隔壁
					'path': activeName,
					'query': this.$route.query,
					'meta': meta,
				})
			},

			addTab: function() {
				let tab = {};
				if (this.$route.path == "/erp/home") {
					return;
				}
				tab.closable = true;
				tab.path = this.$route.path;
				tab.tilte = this.$route.meta.name;
				tab.name = this.$route.name;
				tab.meta = this.$route.meta;
				this.editableTabs.push(tab)
				this.editableTabsValue = this.$route.name;
				sessionStorage.setItem("setTabs2", JSON.stringify(this.editableTabs));
				// console.log("addTab")
			},

			removeTab: function(targetName) {
				// console.log("targetName", targetName)
				let tabs = this.editableTabs
				let activeName = this.editableTabsValue
				// console.log("targetName", targetName)
				if (activeName === targetName) {
					tabs.forEach((tab, index) => {
						if (tab.name === targetName) {
							const nextTab = tabs[index + 1] || tabs[index - 1]
							if (nextTab) {
								activeName = nextTab.name
							}
						}
					});
					this.editableTabsValue = activeName
					if(this.editableTabs.length>1){
						this.editableTabs = tabs.filter((tab) => tab.name !== targetName)
					}
					this.showTab(activeName);
				}else {
					this.editableTabsValue = activeName;
					this.editableTabs = tabs.filter((tab) => tab.name !== targetName)
				}
				sessionStorage.removeItem('newProducts')
			},

			closeOther: function() {
				this.closeRight();
				this.closeLeft();
				this.removeTab(this.editableTabsValue);
			},

			closeRight: function() {
				let tabs = this.editableTabs
				let activeName = this.editableTabsValue
				let activeIndex = 0;
				tabs.forEach((tab, index) => {
					if (tab.name === activeName) {
						activeIndex = index;
					}
				});
				if (activeIndex + 1 < tabs.length) {
					this.editableTabs = tabs.slice(0, activeIndex + 1);
				}

			},

			closeLeft: function() {
				// console.log("111111",this.editableTabs)
				let tabs = this.editableTabs
				let activeName = this.editableTabsValue
				let activeIndex = 0;
				tabs.forEach((tab, index) => {
					if (tab.name === activeName) {
						activeIndex = index;
					}
				});
				if (activeIndex > 1) {
					let mytabs = [tabs[0]];
					tabs.slice(activeIndex, tabs.length).forEach((item) => {
						mytabs.push(item);
					})
					this.editableTabs = mytabs;
				}
			}
		}
	}
</script>

<style lang="less">
	.tab-head {
		flex-grow: 1
	}

	.tab-head .el-tabs {
		--el-tabs-header-height: inherit;
	}

	.tab-head .el-tabs__item.is-active {
		background: #f6f7fb;
		border-bottom: 1px solid var(--el-bg-color) !important;
	}

	.tab-head .el-tabs--bottom .el-tabs__header.is-bottom {
		margin-top: 4px !important;
	}

	.tab-head .el-tabs__item {
		border-bottom: 1px solid #fff !important;
		height: 28px !important;
		line-height: 28px !important;
		font-size: 12px;
	}

	.tab-head .el-tabs--card>.el-tabs__header {
		border-bottom: 0px !important;
	}

	.tab-head .el-tabs__item {
		display: flex;
		align-items: center;
		background: #fff;
	}

	.tab-head .el-tabs__nav {
		display: flex;
	}

	.el-tabs__item:hover {
		color: #f2ab15;
	}
	
	.el-tabs__item.is-active {
		color: #f2ab15;
		background: #f6f7fb;
	}
	
	.tab-head .el-tabs__item.is-active {
		color:#fff;
		background: #f2ab15;
	}
</style>
