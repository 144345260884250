<template>
	<div class="header-right-content">
		<div class="avatar">
			<el-dropdown>
				<span class="el-dropdown-link"  style="display: flex;align-items: center;">
					<div style="display: flex;">
						<span class="user-name" v-if="userInfo.comname">{{userInfo.comname + ' / '}}</span>
						<span class="user-name" v-if="userInfo.deptname">{{userInfo.deptname + ' / '}}</span>
						<span class="user-name" style="padding-right:20px;" v-if="userInfo.rolename">{{userInfo.rolename}}</span>
					</div>
					<el-avatar :src="image"></el-avatar>
					<span class="user-name">{{userInfo.username}}</span>
				</span>
				<template #dropdown>
					<el-dropdown-menu>
						<el-dropdown-item v-for="(item,index) in subUserList" :key="index" @click.native="switchAccounts(item.id)">{{item.username}}</el-dropdown-item>
						<!-- <el-dropdown-item @click="">个人中心</el-dropdown-item> -->
						<el-dropdown-item @click.native="exit">退出</el-dropdown-item>
						<!-- <el-dropdown-item @click="">清缓存</el-dropdown-item> -->
					</el-dropdown-menu>
				</template>
			</el-dropdown>
		</div>
		
		<!-- 分配销售/采购 -->
		<ant-modal :visible="open" :modal-title="'绑定业务'" :adjust-size="true" modal-height="280"
		           modal-width="340" @cancel="cancel">
		    <a-form-model ref="formData" slot="content" :model="formData" :rules="rules" layout="vertical">
		        <!-- 不可选填 -->
				<a-row class="form-row" :gutter="32">
				    <a-col :lg="24" :md="24" :sm="24">
				        <a-form-model-item label="选择账号" prop="usercode">
				            <a-select v-model="formData.usercode" style="width: 100%" show-search optionFilterProp="label" placeholder="请选择">
				                <a-select-option v-for="item in subUserList" :key="item.usercode" :label="item.usercode" :value="item.id">
				                    {{ item.username + '/' + item.usercode }}
				                </a-select-option>
				            </a-select>
				        </a-form-model-item>
				    </a-col>
				</a-row>
		    </a-form-model>
		    <template slot="footer">
		        <a-button type="primary" @click="submitForm">
		            保存
		        </a-button>
		        <a-button @click="cancel">
		            取消
		        </a-button>
		    </template>
		</ant-modal>
	</div>
</template>

<script>
	import Cookies from "js-cookie";
	import AntModal from '@/components/pt/dialog/AntModal' // Modal组件
	import { 
		logout,
		userLand,
		getAllEnMenu
	} from "@/api/home/index.js"
	export default {
		components: {
		   AntModal
		},
		data(){
			return {
				image: "https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png",
				name:"test",
				userInfo:null,//个人信息
				subUserList:null,
				formData:{
					usercode:undefined,
				},
				open:false,
				rules: {
				    usercode: [{
				        required: true,
				        message: '请选择主账号',
				        trigger: 'blur'
				    }],
				},
				ruleForm:{
					id:null
				}
			}
		},
		
		created() {
			this.userInfo = Cookies.get("userInfo")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
			this.subUserList = Cookies.get("subUserList")
			if(this.subUserList){
				this.subUserList = JSON.parse(this.subUserList)
			}
		},
		
		mounted() {
			
		},
		
		watch:{
			'$route'(to,from){
				if(to.fullPath=="/login"){
					window.location.reload();
				}
			}
		},
		
		computed:{
			
		},
		
		methods:{
			//-------------切换账号--------------------
			switchAccounts:function(val){
				// this.open=true;
				console.log(val)
				userLand({
					id:val
				}).then(res => {
					// console.log(res.code)
					if(res.code=="200"){
						Cookies.set("userInfo", JSON.stringify(res.data), {
							expires: 30
						});
						Cookies.set("subUserList", JSON.stringify(res.subUserList), {
							expires: 30
						});
						this.subUserList=res.subUserList
						Cookies.set("role", res.role, {
							expires: 30
						});
						// console.log("1111",res.meunList)
						sessionStorage.setItem("meunList", JSON.stringify(res.meunList))
						this.routeData(res)
					}else{
						this.$message.error(res.data.msg);
					}
				})
			},
			
			// ------------------------绑定弹窗确定按钮-------------------------------
			submitForm: function () {
				console.log("1111")
				this.$refs.formData.validate(valid => {
					if(valid){
						this.ruleForm.id = this.formData.usercode
						userLand(this.ruleForm).then(res => {
							// console.log(res.code)
							if(res.code=="200"){
								Cookies.set("userInfo", JSON.stringify(res.data), {
									expires: 30
								});
								var arr = []
								res.subUserList.map(item=>{
									var suUserObj = {
										id:item.id,
										usercode:item.usercode,
										username:item.username
									}
									arr.push(suUserObj)
								})
								Cookies.set("subUserList", JSON.stringify(arr), {
									expires: 30
								});
								Cookies.set("role", res.role, {
									expires: 30
								});
								// console.log("1111",res.meunList)
								sessionStorage.setItem("meunList", JSON.stringify(res.meunList))
								this.routeData(res)
							}else{
								this.$message.error(res.data.msg);
							}
						})
					}
				})
			},
			
			//------------------------------路由重组-----------------------------------------
			routeData:function(e){
				let data = []
				e.meunList.map(res=>{
					let arr = {
						path: res.menuenname,
						title: res.menuname,
						name: res.menupath,
						closable: true,
						meta: {
						  name: res.menuname,
						  icon: res.menuico,
						  activeMenu: res.menupath,
						  keepAlive: res.keeplive,
						  type: res.isvisible
						},
						children:[]
					}
					// console.log(res.subList)
					if(res.subList!==undefined){
						res.subList.map(res2=>{
							let arr2 = {
								path: res2.menuenname,
								title: res2.menuname,
								name: res2.menupath,
								closable: true,
								meta: {
								  name: res2.menuname,
								  icon: res2.menuico,
								  activeMenu: res2.menupath,
								  keepAlive: res2.keeplive,
								  type: res2.isvisible,
								  url: res2.url,
								},
							}
							arr.children.push(arr2)
						})
					}
					data.push(arr)
				})
				// console.log("1231212",data)
				this.$store.dispatch("setRouteMenuList", {
				    routeMenuList: JSON.stringify(data),
				});
				let role = {
					roleId:e.role
				}
				getAllEnMenu(role).then(res=>{
					// console.log("getMenuByRoleId",res)
					let getAllEnMenu = []
					res.data.map(res=>{
						getAllEnMenu.push(res.menuenname)
					})
					this.$store.dispatch("setRoleList", {
					    roleList: JSON.stringify(getAllEnMenu),
					});
					sessionStorage.removeItem("setTabs2")
					
					window.location.replace("/erp/home")
					// this.$router.go(0)
				})
				
			},
			
			// ------------------------绑定弹窗取消按钮-------------------------------
			cancel: function () {
				this.open = false;
			},
			
			//--------------退出-----------------------
			exit:function(){
				// console.log("2312")
				sessionStorage.removeItem("setTabs")
				sessionStorage.removeItem("setTabs2")
				Cookies.remove('role');
				sessionStorage.removeItem("meunList")
				Cookies.remove('userInfo');
				Cookies.remove('subUserList');
				this.$store.dispatch("setXlsxName", {
				    xlsxName: null,
				});
				this.$store.dispatch("setToBeQuotedList", {
				    toBeQuotedList: null,
				});
				this.$store.dispatch("setQuotedList", {
				    quotedList: null,
				});
				this.$store.dispatch("setBomSize", {
				    bomSize: null,
				});
				this.$store.dispatch("setCacheIndexArray", {
				    cacheIndexArray:{
				    	procureIndex:'待报价',
				    	collVerifyIndex:'未确认',
				    	sellOrderIndex:6,
				    	collpayIndex:'收款',
				    	sellReturnIndex:'退货申请',
				    	purchaseReturnIndex:'退货列表',
				    	performanceIndex:'订单',
				    	purPerformanceIndex:'订单',
				    	sellPerformanceIndex:'订单',
				    	makeCollIndex:'开票',
				    	sellRebateIndex:4,
				    	purchaseRebate:'返点申请',
						sellReturnMoney:'0'
				    },
				});
				this.$store.dispatch("setCustomerList",{
					customerList: {
						userList:[],
						addressList:[],
						invoceList:[],
						loanList:[],
					}
				});
				this.$store.dispatch("setRouteMenuList", {
				    routeMenuList: null,
				});
				this.$store.dispatch("setPlainOptions", {
				    plainOptions: null,
				});
				this.$store.dispatch("setXlsxDataList", {
				    xlsxDataList: [],
				});
				this.$store.dispatch("setBomDataList", {
				    bomDataList: [],
				});
				this.$store.dispatch("setBomForm", {
				    bomForm: null,
				});
				this.$store.dispatch("setAnotherOrderList",{
					anotherOrderList: null
				})
				this.$store.dispatch("setAddExcelFrom", {
					addExcelFrom: null,
				});
				this.$store.dispatch("setAddInventoryList", {
					addInventoryList: null,
				});
				this.$store.dispatch("setSellAskExcel", {
					sellAskExcel: null,
				});
				logout().then(res=>{
					if(res.code=="200"){
						this.$router.push({
							path: '/userLogin',
						})
					}else{
						this.$message.error(res.msg)
					}
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	.avatar {
		padding: 0px 12px;
		line-height:34px;
		display: flex;
		align-items: center;
	}
	.header-right-content{
		display: flex;
		align-items: center;
	}
	.header-right-content .el-avatar{
		width:24px;
		height: 24px;
	}
	.user-name{
		font-size: 13px;
		color: #000;
		margin-left: 8px;
	}
</style>